<template>
	<div class="send_http">
		<v-dialog
			v-model="dialog"
			transition="dialog-bottom-transition"
			fullscreen
			hide-overlay
			scrollable
		>
			<v-card tile>
				<!--				<v-toolbar elevation="0">-->
				<!--				</v-toolbar>-->
				<v-card-text>
					<div class="modal_header">
						<div class="close" @click="close_cb">
							<Icon name="close"/>
						</div>
					</div>
					<div class="modal_body">
						<h2 class="modal_title" v-if="this.data_render.card=='robot'">Реплика робота</h2>

						<h2 class="modal_title" v-if="this.data_render.card=='answer'">Ответы клиента</h2>

						<h2 class="modal_title" v-if="this.data_render.card=='send_sms'">Отправить смс</h2>
						<h2 class="modal_title" v-if="this.data_render.card=='send_http'">Отправить http запрос</h2>
						<h2 class="modal_title" v-if="this.data_render.card=='send_tg'">Отправить сообщение в
							телеграм</h2>
						<h2 class="modal_title" v-if="this.data_render.card=='blacklist'">Черный список</h2>
						<h2 class="modal_title" v-if="this.data_render.card=='callcenter'">Перевод звонка</h2>
						<h2 class="modal_title" v-if="this.data_render.card=='target'">Цель</h2>

						<h2 class="modal_title" v-if="this.data_render.card=='forwarding'">Переадресация на блок</h2>
						<h2 class="modal_title" v-if="this.data_render.card=='amocrm'">Интеграция с amoCRM</h2>
						<h2 class="modal_title" v-if="this.data_render.card=='bitrix'">Интеграция с Bitrix24</h2>

						<div class="nick_name_block" v-if="showNickName">
							<h3>Псевдоним блока</h3>
							<v-text-field
									height="40"
									v-model="data_render.nickname"
									placeholder="Укажите псевдоним блока"
									hide-details="auto"
									:rules="[rules.validNick, rules.counter]"
							></v-text-field>
						</div>
						<div class="subtitler" v-if="this.data_render.card=='answer'">Выберите готовые, созданные
							искусственным интеллектом ответы или сделайте свои.
						</div>


						<RobotReplic v-if="this.data_render.card=='robot'" :data="data_render" :edit_callback="edit_callback" @valid="validEmit"/>
						<AnswerClient v-if="this.data_render.card=='answer'" :data="data_render" :edit_callback="edit_callback" @valid="validEmit"/>
						<SendSms v-if="this.data_render.card=='send_sms'" :data="data_render" :edit_callback="edit_callback"/>
						<SendHttp v-if="this.data_render.card=='send_http'" :data="data_render" :edit_callback="edit_callback"/>
						<BlackList v-if="this.data_render.card=='blacklist'" :data="data_render" :edit_callback="edit_callback"/>
						<SendTelegram v-if="this.data_render.card=='send_tg'" :data="data_render" :edit_callback="edit_callback"/>
						<CallCenter v-if="this.data_render.card=='callcenter'" :data="data_render" :edit_callback="edit_callback"/>
						<TargetPoint v-if="this.data_render.card=='target'" :data="data_render" :edit_callback="edit_callback"/>
						<ForwardingBlock v-if="this.data_render.card=='forwarding'" :data="data_render" :allBlocks="allBlocksName" :edit_callback="edit_callback"/>
						<IntegrationAmoCRM v-if="this.data_render.card=='amocrm'" :data="data_render" :edit_callback="edit_callback"/>
						<IntegrationBitrix24 v-if="this.data_render.card=='bitrix'" :data="data_render" :edit_callback="edit_callback"/>

						<div class="button_container">
							<div class="redValid" v-if="this.data_render.card=='answer' && !validAnswerWords">Нельзя сохранить если в группах есть одинаковые слова!</div>
              <div class="redValid" v-if="this.data_render.card == 'send_http' && !validSendHTTP">Параметры запроса не корректны</div>
							<div class="save btn_save" @click="save" >Сохранить</div>
							<div class="cancel_btn" @click="close_cb">Отменить</div>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import Icon from '../../SetIcon.vue'
import RobotReplic from './Editors/RobotReplic.vue'
import AnswerClient from './Editors/AnswerClient.vue'
import SendSms from './Editors/SendSms.vue'
import SendTelegram from './Editors/SendTelegram.vue'
import SendHttp from './Editors/SendHttp.vue'
import CallCenter from './Editors/CallCenter.vue'
import TargetPoint from './Editors/TargetPoint.vue'
import ForwardingBlock from './Editors/Forwarding.vue'
import BlackList from './Editors/BlackList.vue'
import IntegrationAmoCRM from "@/components/Modal/Scenaries/Editors/IntegrationAmoCRM";
import IntegrationBitrix24 from "@/components/Modal/Scenaries/Editors/IntegrationBitrix24";

export default {
	props: ['show_dialog', 'close', 'data'],
	components: {
		Icon,
		RobotReplic,
		AnswerClient,
		SendSms,
		SendHttp,
		SendTelegram,
		BlackList,
		CallCenter,
		TargetPoint,
		ForwardingBlock,
		IntegrationAmoCRM,
		IntegrationBitrix24
	},
	watch: {
		show_dialog: function (val) {
			this.dialog = val
			let local_data=JSON.parse(JSON.stringify(this.data))
			this.data_save = local_data
			this.data_render = local_data

			this.allBlocksName = window.id_all_blocks(this.data_render)

			if (!val) {
				window.stop_edit()
			} else {
				window.start_edit()
			}

		},
	},
	methods: {
		validEmit(obj){
			this.validForm = obj
		},

		save() {
      if(this.data && this.data.typeRequest && this.data.typeRequest !== 'POST'){
        delete this.data.params
        delete this.data.postURL
      }
			let _this = this
			function saveFun(){
				if (_this.data_save) {
					window.data_save(_this.data_save)
					_this.close_cb()
				}
			}
			// Все проверки писать в этот if проверки валидации псевдонима
			if (this.validForm && this.rules.validNick(this.data_render.nickname) === true && this.rules.counter(this.data_render.nickname) === true) {
				if (this.data_render.card === 'robot') {
					if (this.data_render.type === 0) {
						if (this.validForm.aria) saveFun()
					} else if (this.data_render.type === 1) {
						if (this.validForm.file_play) saveFun()
					} else if (this.data_render.type === 2) {
						if (this.validForm.isRecord) saveFun()
					}
				} else if (this.data_render.card === 'answer') {
					let arrAllWords = []

					for(let key in this.data_render.box){
						if(Array.isArray(this.data_render.box[key].items)) arrAllWords = [...arrAllWords, ...this.data_render.box[key].items]
					}
					arrAllWords = arrAllWords.map((item) => item.text)
					if(new Set(arrAllWords).size === arrAllWords.length) {
						this.validAnswerWords = true
						saveFun()
					} else {
						this.validAnswerWords = false
					}
				} else {
					saveFun()
				}
			}
		},
		edit_callback(data) {
			console.log('🚀 -> edit_callback -> data:', data)
      if(data && data.typeRequest && data.typeRequest === 'POST'){
        // this.data.validSendHTTP = 
        try{
          let params = data.params.replaceAll('\n', '').replaceAll(' ', '').replaceAll('\t', '')
          JSON.parse(params)
          this.validSendHTTP = true
        }
        catch(err){
          this.validSendHTTP = false
        }
      }
			this.data_render = data
			this.data_save = data
      
		},
		close_cb() {
			this.data_save = false
			this.data_render = false
			this.dialog = false
			this.close()
			this.nickname = ''
			this.validForm = true
		},
	},
	computed: {
		showNickName() {
			return this.data_render.card !== 'target' && this.data_render.card !== 'blacklist' // && this.data_render.card !== 'answer'
		},
		nickNameBlock(){
			return Object.values(this.allBlocksName)
		}
	},
	data() {
		return {
			dialog: false,
			data_save: {},
			data_render: {},
			nickname: '',
			allBlocksName: [],
			rules: {
				validNick: value => !(this.nickNameBlock.findIndex( nick => nick === value) > -1) || 'Такой псевдоним уже используется',
				counter: value => value === undefined ? true : (value.length <= 12) || 'Максимальная длинна псевдонима 12 символов'
			},
			validForm: true,
			validAnswerWords: true,
      validSendHTTP: true
		}
	},
}
</script>
<style scoped>
.redValid {
	color: #DE4040;
}
.btn_unlock {
	font-style: normal;
	font-weight: 500;
	text-align: left;
	cursor: pointer;
	margin-bottom: 19px;
	font-size: 15px;
	line-height: 120%;
	color: #00809C;
}

.form {
	display: inline-block;
	max-width: 600px;
}

.play {
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 120%;
	color: #00809C;
}

.btn_play {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 12px 0 0 0 !important;
	cursor: pointer;
}

.btn_play .icon {
	margin-right: 4px;
}

.my_input {
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 120%;
	color: #808185;
	position: relative;
}

.my_input .icon {
	position: absolute;
	right: 7px;
	top: 17px;
}

.subtitle {
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #404247;
	text-align: left;
	margin-bottom: 6px;
}

.modal_header {
	display: flex;
	align-self: flex-end;
}

.item_tab {
	margin: 32px auto 0 auto;
	max-width: 600px;
}

.modal_body {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
}

.form_container {
	text-align: center;
	width: 100%;
}

.button_container {
	display: block;
	width: 100%;
}

.save {
	padding: 10px 20px 12px;
	background: #FEE600;
	border-radius: 8px;
	margin: 16px 0 0 0;
	max-width: 600px;
	display: inline-block;
}

.paste {
	padding: 9px 20px 10px;
	background: #FFFFFF;
	border: 1px solid #FEE600;
	border-radius: 8px;
	margin: 0;
	cursor: pointer;
	max-width: 235px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 110%;
	color: #2B2D33;
}

.modal_title {
	margin-top: 32px;
	margin-bottom: 32px;
}

h3 {
	margin-bottom: 12px;
	text-align: left;
}

.close {
	margin-right: 52px;
	margin-top: 20px;
}

.nick_name_block {
	max-width: 600px;
	margin: 0 auto 30px auto;
	width: 600px;
}

@media (max-width: 600px) {
	.modal_body {
		padding: 15px;
	}

	.nick_name_block {
		width: 100%;
	}
}
</style>
