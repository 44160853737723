<template>
  <div class="http">
    <h3>Тип запроса</h3>
    <v-select
      label="Тип запроса"
      height="40"
      hide-details
      item-color="#fee600"
      :items="['GET', 'POST']"
      v-model="data_local.typeRequest"
      solo
      @change="onChangeType"
    />
    <br />

    <!-- Если GET -->
    <template v-if="data_local.typeRequest === 'GET'">
      <h3>Postback url</h3>
      <v-textarea
        class="my_textarea"
        v-model="data_local.link"
        auto-grow
        placeholder="http://kakayato/link?subid={s}"
        @input="onInput"
      />
    </template>

    <!-- Если POST -->
    <template v-if="data_local.typeRequest === 'POST'">
      <h3>URL для POST-запроса</h3>
      <v-text-field
        height="40"
        v-model="data_local.postURL"
        placeholder="http://sitename"
        hide-details="auto"
        @input="onInput"
      />
      <br />
      <h3>Параметры запроса в формате JSON</h3>
      <v-textarea
        v-model="data_local.params"
        placeholder='{"phone": propsName, "callName": propsCallName, "record": propsRecord, "target": propsTarget}'
        @input="onInput"
      />
    </template>

    <!-- Вставка подстановок -->
    <div class="paste" @click="paste('phone')">Вставить номер клиента</div>
    <div class="paste" @click="paste('callname')">Вставить название обзвона</div>
    <div class="paste" @click="paste('target')">Вставить название цели</div>
    <div class="paste" @click="paste('answer')">Вставить текст ответа</div>
  </div>
</template>

<script>
export default {
  name: "SendHttp",
  props: ["data", "edit_callback"],

  data() {
    return {
      // Локальные данные, которые мы будем редактировать
      data_local: {
        link: "",
        typeRequest: "GET",
        postURL: "",
        params: "{\n}"
      }
    };
  },

  watch: {
    // При любом изменении входящего prop "data"
    // делаем глубокую копию в data_local,
    // чтобы данные блоков не пересекались
    data: {
      deep: true,
      immediate: true,
      handler(newVal) {
        // Глубокая копия пропа
        const cloneData = JSON.parse(JSON.stringify(newVal || {}));

        // Если тип запроса не задан — ставим GET
        if (!cloneData.typeRequest) {
          cloneData.typeRequest = "GET";
        }

        // Для POST задаём дефолты, если вдруг пусто
        // if (cloneData.typeRequest === "POST") {
        //   if (!cloneData.postURL) {
        //     cloneData.postURL = "https://";
        //   }
        //   if (!cloneData.params) {
        //     cloneData.params = "{\n\tphoneNumber:\n }";
        //   }
        // }

        // Для GET, если link пуст, пусть будет пустая строка
        if (!cloneData.link) {
          cloneData.link = "";
        }

        // Записываем в локальное состояние
        this.data_local = cloneData;
      }
    }
  },

  methods: {
    // Универсальная вставка значений
    paste(type) {
      // Логика по умолчанию: добавляем в link
      // (При желании можно разделить: если POST, то data_local.params += ...)
      this.data_local.link += `{${type}}`;
      this.emitChange();
    },

    // Срабатывает при вводе текста, уведомляем родителя
    onInput() {
      this.emitChange();
    },

    // При смене типа запроса (GET -> POST / POST -> GET)
    onChangeType() {
      // Если только что выбрали POST,
      // подставляем дефолтные значения, если их нет
      // if (this.data_local.typeRequest === "POST") {
      //   if (!this.data_local.postURL) {
      //     this.data_local.postURL = "https://";
      //   }
      //   if (!this.data_local.params) {
      //     this.data_local.params = "{\n\tphoneNumber:\n }";
      //   }
      // }
      this.emitChange();
    },

    // Функция, уведомляющая родителя о новых данных
    emitChange() {
      if (typeof this.edit_callback === "function") {
        // На всякий случай передаём КОПИЮ, чтобы исключить мутацию извне
        const cloned = JSON.parse(JSON.stringify(this.data_local));
        this.edit_callback(cloned);
      }
    }
  }
};
</script>

<style scoped>
.modal_header {
  display: flex;
  align-self: flex-end;
}

.http {
  max-width: 600px;
  margin: 0 auto;
  width: 600px;
}

.modal_body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 554px;
}

.save {
  padding: 10px 20px 12px;
  background: #FEE600;
  border-radius: 8px;
  margin: 16px 0 0 0;
}

.paste {
  padding: 9px 20px 10px;
  background: #FFFFFF;
  display: inline-block;
  border: 1px solid #FEE600;
  border-radius: 8px;
  margin: 12px 0 0 10px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}

.modal_title {
  margin-top: 32px;
  margin-bottom: 32px;
}

h3 {
  margin-bottom: 12px;
  text-align: left;
}

.close {
  margin-right: 52px;
  margin-top: 20px;
}

@media (max-width: 650px) {
  .http {
    width: auto;
  }
}
</style>
